import React from "react";
import '../../components/Layout/layout.css';

const BlockQuote = ({ children }) => {
  return (
    <div className="quote-div mtop-2">
      <blockquote>
        {children}
      </blockquote>
    </div>
        
  );
};

export default BlockQuote;
